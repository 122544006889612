export const organisations: TableModule = {
    name: "organisations",
    route: "spa.settings.organisations",
    columns: [
        {
            name: "id",
            sortable: true
        },
        {
            name: "name",
            pageLink: true,
            sortable: true,
            sortBy: "name",
            mobile: true
        },
        {
            name: "email",
            sortable: true
        },
        {
            name: "active",
            sortable: true
        },
        {
            name: "created_at",
            sortable: true
        }
    ],
    filters: [
        {
            name: "id",
            column: "organisations.id",
            type: "numeric"
        },
        {
            name: "name",
            column: "organisations.name",
            type: "string"
        },
        {
            name: "active",
            column: "organisations.active",
            type: "boolean"
        },
        {
            name: "email",
            column: "organisations.email",
            type: "string"
        }
    ],
    tools: [
        {
            isCreateAction: true,
            ability: ["create", "organisations"]
        },
        {
            name: "organisations.toggle",
            color: "rose"
        },
        {
            name: "general.export"
        }
    ]
}
